import React, {useContext, useState} from 'react';
import Languages from './LanguageSwitcher';
import './Header.sass';
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {DataContext} from "../context/DataContext";
import {Helmet} from "react-helmet-async";

const Header = () => {
    const { t } = useTranslation();
    const lang = i18n.resolvedLanguage;
    const {data} = useContext(DataContext);
    const [contacts] = useState(data?.contacts);
    const [burgerIsOpen, setBurgerIsOpen] = useState(false)

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
    })
    const handleMenuOpen = () => {
        setBurgerIsOpen(!burgerIsOpen);
        document.body.classList.toggle('no-scroll', !burgerIsOpen);
    }
    const links = [
        {
            index: "main",
            title: t("main"),
            link: "#intro"
        },
        {
            index: "about",
            title: t("about_us"),
            link: "#about"
        },
        {
            index: "products",
            title: t("products"),
            link: "#products"
        },
        {
            index: "contacts",
            title: t("contacts"),
            link: "#contacts"
        },
    ]
    
    return (

        <header className="header">

            <Helmet>
                <title>{ data?.static?.seo?.title || "" }</title>
                <meta
                    name="description"
                    content={ data?.static?.seo?.description || ""}
                />
                <meta
                    name="keywords"
                    content={ data?.static?.seo?.keywords || ""}
                />
            </Helmet>
          <div className="header-box container">
            <div className="burger-btn-container" onClick={handleMenuOpen}>
                <div className={`burger-btn ${burgerIsOpen ? "active" : ""}`}>
                    <span></span>
                </div>
            </div>
            <div className="header-logo">
                <a href='./' className='logo'>MULTIMETALL</a>
            </div>
            <nav className={`nav ${burgerIsOpen ? "active-menu" : ""}`}>
                <div className="mobile-nav-menu">
                    <Languages/>
                    <div className="burger-btn-container" onClick={handleMenuOpen}>
                    <div className={`burger-btn ${burgerIsOpen ? "active" : ""}`}>
                        <span></span>
                    </div>
                </div>
                
                </div>
                <div className="nav-logo"><a href='./' className='logo'>OOO MULTIMETALL</a></div>
                <div className="nav-box">
                {links.map((item, index) => (
                    <div className="link-container" key={index}>
                        <a href={item.link} onClick={windowWidth < 912 ? handleMenuOpen : ()=>{}} className="nav-link">{item.title}</a>
                    </div>
                ))}
                <div className="link-container">
                    <div className="meta-info">
                        <p className="dev-info">{ t("developer") + ": " } <a href="https://ev-sites.com" target={"_blank"} rel="noreferrer" className="dev-link">ev-sites.com</a></p>
                    </div>
                </div>
                </div>
                
            </nav>
            <div className="header-contacts">
                <div className="contacts-box phone">
                    <div className="icon">
                        <i className='icon-phone'></i>
                    </div>
                    <div className="contact">
                        {contacts?.phones && contacts.phones.map((phone, index) =>
                            (phone &&
                                (
                                    <a href={`tel:${phone.replaceAll(/[^0-9+]/g, "")}`}
                                       className='contact-link' key={index}>{phone}</a>
                                ))
                        )}
                    </div>
                </div>
                <div className="contacts-box location">
                    <div className="icon">
                        <i className='icon-location'></i>
                    </div>
                    <div className="contact-location">
                        <p className='location'>{contacts?.address ? contacts.address[lang] : ""}</p>
                    </div>
                </div>
                <Languages/>
            </div>
          </div>
        </header>
    );
  }

  export default Header;
  