// import langEn from "../assets/images/engLanguage.svg"
import langRu from '../assets/images/ru.svg'
import langUz from '../assets/images/uzb.svg'
import './LanguageSwitcher.sass'
import React, { useState } from 'react'
import { useTranslation } from "react-i18next";

const languageOptions = [
  // {
  //   id: "en",
  //   flagimg: langEn,
  //   text: ''
  // },
  {
    id: 'ru',
    flagimg: langRu,
    text: 'Рус',
  },
  {
    id: 'uz',
    flagimg: langUz,
    text: 'O’zb',
  },
]

const defaultLangFlag = <img src={languageOptions[0].flagimg} height="30" width="30" alt="nope" />
const defaultText = languageOptions[0].text

const Languages = () => {
  const [cssDisplay, setCssDisplay] = useState('none')
  const [langFlag, setLangFlag] = useState(defaultLangFlag)
  const [displayText, setDisplayText] = useState(defaultText)


  const { i18n } = useTranslation();

  const showDropdown = () => {
    setCssDisplay(cssDisplay === 'none' ? 'block' : 'none')
  }

  const selectListItem = (event) => {
    const selectedLangId = event.target.id
    const selectedLang = languageOptions.find((lang) => lang.id === selectedLangId)
    if (selectedLang) {
      handleLanguageChange(selectedLang)
      setCssDisplay('none')
      setLangFlag(<img src={event.target.src} height="30" width="30" alt="nope" />)
      setDisplayText(selectedLang.text)
    }
  }

  const handleLanguageChange = (selectedLang) => {
    userLanguageChange(selectedLang)
  }

  const userLanguageChange = (selectedLang) => {
    i18n.changeLanguage(selectedLang.id);
  }

  return (
    <div className="languages">
      <button className="lang-btn" onClick={showDropdown}>
        <div className="lang-img">{langFlag}</div>
        <div className="lang-text">
          <p className="text">{displayText}</p>
        </div>
        <div className="icon">
          <span className="icon-arrow"></span>
        </div>
      </button>
      <ul className="list" style={{ display: cssDisplay }}>
        {languageOptions.map((lang) => (
          <li className="list-item" id={lang.id} key={lang.id} disabled>
            <div className="lang-img">
              <img onClick={selectListItem} src={lang.flagimg} height="30" width="30" alt="flagpic" id={lang.id} />
            </div>
            <div className="lang-text">
              <p className="text">{lang.text}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Languages