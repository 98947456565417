import React, {useContext, useState} from 'react'
import contactImage from '../assets/images/3.webp'
import './Contacts.sass'
import {useTranslation} from "react-i18next";
import {DataContext} from "../context/DataContext";
import i18n from "i18next";

const Contacts = () => {
    const {t} = useTranslation();
    const lang = i18n.resolvedLanguage;
    const {data} = useContext(DataContext);
    const [contacts] = useState(data?.contacts);
    return (
        <div className='contacts' id="contacts">
            <div className="contacts-box mobile-border">
                <div className="container">
                    <div className="block-heading">
                        <h3 className='heading'>{t("our_contacts")}</h3>
                    </div>
                    <div className="box">
                        <div className="box-items">
                            <div className="item">
                                <img className='image' src={contactImage} alt=""/>
                            </div>
                            <div className="item">
                                <div className="item-block">
                                    <div className="block">
                                        <div className="item-icon">
                                            <i className={'icon-location'}></i>
                                        </div>
                                        <div className="block-information">
                                            <div className="item-heading"><h5
                                                className='heading'>{t("our_address")}</h5></div>
                                            <div className="item-text"><p
                                                className='text'>{contacts?.address ? contacts.address[lang] : ""}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item-block">
                                    <div className="block">
                                        <div className="item-icon">
                                            <i className={'icon-phone'}></i>
                                        </div>
                                        <div className="block-information">
                                            <div className="item-heading"><h5
                                                className='heading'>{t("orders_accepting")}</h5></div>
                                            <div className="item-text">
                                                {contacts?.phones && contacts.phones.map((phone, index) =>
                                                    (phone &&
                                                        (
                                                            <a href={`tel:${phone.replaceAll(/[^0-9+]/g, "")}`}
                                                               className='text' key={index}>{phone}</a>
                                                        ))
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacts