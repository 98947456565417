const config = {
    apiUrl: "https://api.multimetall.co.uz/api/data",
    localization: {
        supportedLangs: [
            "ru",
            "uz"
        ],
        defaultLang: "ru",
        fallbackLang: "ru",
    }
}

export default config;