import React, {useContext, useState} from 'react'
import aboutImg from '../assets/images/2.webp'
import mobileImage from '../assets/images/aboutImageMobile.webp'
import './About.sass'
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {DataContext} from "../context/DataContext";

const About = () => {
    const { t } = useTranslation();
    const lang = i18n.resolvedLanguage;
    const {data} = useContext(DataContext);
    const [content] = useState(data?.static?.about);
    const [fileLink] = useState(data?.static?.booklet);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
    })

  return (
    <div className='about' id="about">
        <div className="about-box mobile-border">
            <div className="container">
                <div className="block-heading only-mobile">
                    <h3 className='heading'>{ t("about_company") }</h3>
                </div>
                <div className="about-block">
                    <div className="block-items">
                        <div className="block-heading only-desktop">
                            <h3 className='heading'>{ t("about_company") }</h3>
                        </div>
                        <div className="block-text">
                            <p className='text'>{ content ? content[lang] : "" }</p>
                        </div>
                        {/*<Button text={ t("learn_more") } className="sm"/>*/}
                        <div className="button">
                            <a href={fileLink || ""} target={"_blank"} rel="noreferrer" download className={"btn sm"} >{ t("learn_more") }</a>
                        </div>
                    </div>
                    <div className="block-item-image">
                        <img className='image only-desktop' src={aboutImg} alt="about" />
                        {windowWidth <= 576 && 
                            <img className='image' src={mobileImage} alt="about" />
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About