import React, {useContext, useState} from 'react'
import AdvantageItem from './AdvantageItem'
import './Advantages.sass'
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {DataContext} from "../context/DataContext";

const Advantages = () => {
    const { t } = useTranslation();
    const lang = i18n.resolvedLanguage;
    const {data} = useContext(DataContext);
    const [content] = useState(data?.advantages);
    return (
    <div className='advantages'>
        <div className="advantages-box mobile-border">
            <div className="container">
                <div className="block-heading">
                    <h3 className='heading'>{ t("our_advantages") }</h3>
                </div>
                <div className="block-items">
                    {content && content.map((item, index) => (
                        <AdvantageItem item={item} index={index} lang={lang} key={index}/>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Advantages