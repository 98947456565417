import React, {useContext} from 'react'
import './About.sass'
import {DataContext} from "../context/DataContext";
import Header from "./Header";
import Intro from "./Intro";
import OurProducts from "./OurProducts";
import About from "./About";
import Advantages from "./Advantages";
import Contacts from "./Contacts";
import Footer from "./Footer";

const Main = () => {
    const {isLoading} = useContext(DataContext);

  return ( isLoading ?
      <div className={"app loading"} ></div>
      :
      (
          <div className='app'>
              <Header/>
              <Intro/>
              <OurProducts/>
              <div className='big-bg'>
                  <About/>
                  <Advantages/>
              </div>
              <Contacts/>
              <Footer/>
          </div>
      )
  )
}

export default Main