const resources = {
    // Russian
    ru: {
        translation: {
            main: "Главная",
            about_us: "О нас",
            products: "Продукция",
            contacts: "Контакты",
            contact_us: "Связаться",
            our_products: "Наша продукция",
            about_company: "О компании",
            learn_more: "Узнать больше",
            details: "Подробнее",
            our_advantages: "Наши преимущества",
            our_contacts: "Наши контакты",
            our_address: "Наш адрес",
            orders_accepting: "Прием заказов и получение консультации",
            developer: "Разработчик",
        },
    },
    // Uzbek
    uz: {
        translation: {
            main: "Bosh",
            about_us: "Haqimizda",
            products: "Mahsulotlar",
            contacts: "Aloqa",
            contact_us: "Muloqot",
            our_products: "Mahsulotlarimiz",
            about_company: "Kompaniya haqida",
            learn_more: "Ko'proq",
            details: "Ba'tafsil",
            our_advantages: "Afzalliklarimiz",
            our_contacts: "Aloqalarimiz",
            our_address: "Manzil",
            orders_accepting: "Buyurtmalarni qabul qilish va maslahat olish",
            developer: "Dasturchi",
        },
    },
};

export default resources;