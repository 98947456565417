import React, {useState} from 'react'
import Button from './Button'
import ModalBox from './ModalBox'
import "./Product.sass"
import {useTranslation} from "react-i18next";

const Product = ({item, index, lang}) => {
    const { t } = useTranslation();
    const [modal, setModal] = useState(false);
  const toggleModal = () => {
      setModal(!modal)
  }

    return (
    <div className='product'>
        <div className="product-box">
            <div className="product-image">
                <img className='image' src={item.img} alt="img" />
            </div>
            <div className="info">
                <div className="product-text">
                    <p className='text'>{item.title[lang]}</p>
                </div>
                <>
                    <Button className="w-full" text={ t("details") } onClick={toggleModal}/>
                    {modal && (
                        <ModalBox onClick={toggleModal} modalTitle={item.title[lang]} modalText={item.content[lang]} modaImage={item.img}/>
                    )}
                </>
            </div>
        </div>
    </div>
  )
}

export default Product