import React, {createContext, useEffect, useState} from 'react'
import axios from "axios";
import config from "../config";
export const DataContext = createContext()
const DataContextProvider = (props) => {
    const [data, setData] = useState({});
    const [isLoading, setLoading] = useState(true);
    const url = config.apiUrl;
    useEffect(() => {
        axios.get(url)
            .then(response => setData(response.data))
            .catch(error => console.error("An error occurred", error))
            .finally(()=> {
                setLoading(false)
            })
    },[])
    return (
        <DataContext.Provider
            value={{
                data,
                isLoading
            }}>
            {props.children}
        </DataContext.Provider>
    )
}
export default DataContextProvider