import React, {useContext, useState} from 'react'
import Button from './Button'
import './Intro.sass'
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {DataContext} from "../context/DataContext";

const Intro = () => {
    const { t } = useTranslation();
    const lang = i18n.resolvedLanguage;
    const {data} = useContext(DataContext);
    const [content] = useState(data?.static?.main);
    const [contacts] = useState(data?.contacts);
    return (
    <div className='intro' id='intro'>
        <div className="intro-box container">
            <div className="intro-heading"><h1 className='heading'>{ content?.title ? content.title[lang] : "" }</h1></div>
            <div className="intro-text">
                <p className='text'>{ content?.subtitle ? content.subtitle[lang] : "" }</p>
            </div>
            <a href={`tel:${contacts?.phones && contacts.phones[0] ? contacts?.phones[0].replaceAll(/[^0-9+]/g, "") : ""}`}>
              <Button className="sm" text={ t("contact_us") }/>
            </a>
        </div>
    </div>
  )
}

export default Intro