import React from 'react'
import './AdvantageItem.sass'

const AdvantageItem = ({item, index, lang}) => {
  return (
    <div className='advantage-item' key={index}>
        <div className="item-box ">
            <div className="item">
                <div className="item-icon">
                    <img src={item?.icon || ""} alt="" className="icon"/>
                </div>
            </div>
            <div className="item">
                <div className="item-heading">
                    <h4 className='heading'>{item.title ? item.title[lang] : ""}</h4>
                </div>
                <div className="item-text">
                    <p className='text'>{item.content ? item.content[lang] : ""}</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdvantageItem