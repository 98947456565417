import React from 'react'
import './ModalBox.sass'

const ModalBox = ({onClick, modalTitle, modalText, modaImage}) => {
  return (
    <div className="modal">
        <div className="container">
            <div className="modal-box">
                <div className='close-modal' onClick={onClick}></div>
                <div className="modal-content">
                    <div className="image">
                        <img src={modaImage} alt="modal" />
                    </div>
                        <div className="description">
                        <div className="description-title">
                            <h3 className='title'>{modalTitle}</h3>
                        </div>
                        <div className="description-text">
                            <p className='text'>{modalText}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ModalBox