import React, {useContext, useState} from 'react'
import './Footer.sass'
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {DataContext} from "../context/DataContext";

const Footer = () => {
    const { t } = useTranslation();
    const lang = i18n.resolvedLanguage;
    const {data} = useContext(DataContext);
    const [contacts] = useState(data?.contacts);
    const links = [
        {
            index: "main",
            title: t("main"),
            link: "#intro"
        },
        {
            index: "about",
            title: t("about_us"),
            link: "#about"
        },
        {
            index: "products",
            title: t("products"),
            link: "#products"
        },
        {
            index: "contacts",
            title: t("contacts"),
            link: "#contacts"
        },
    ]

    return (
    <footer className="footer">
        <div className="footer-box container">
            <div className="footer-logo">
                <a href='./' className='logo'>MULTIMETALL</a>
            </div>
            <nav className="nav">
                {links.map((item) => (
                    <a key={item.index} href={item.link} className="nav-link">{item.title}</a>
                ))}
            </nav>
            <div className="footer-contacts">
                <div className="contacts-box phone">
                    <div className="icon">
                        <i className='icon-phone'></i>
                    </div>
                    <div className="contact">
                        {contacts?.phones && contacts.phones.map((phone, index) =>
                            (phone &&
                                (
                                    <a href={`tel:${phone.replaceAll(/[^0-9+]/g, "")}`}
                                       className='contact-link' key={index}>{phone}</a>
                                ))
                        )}
                    </div>
                </div>
                <div className="contacts-box location">
                    <div className="icon">
                        <i className='icon-location'></i>
                    </div>
                    <div className="contact-location">
                        <p className='location'>{contacts?.address ? contacts.address[lang] : ""}</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-box container meta-info">
            <p className="dev-info">{ t("developer") + ": " } <a href="https://ev-sites.com" target={"_blank"} rel="noreferrer" className="dev-link">ev-sites.com</a></p>
        </div>
    </footer>
  )
}

export default Footer