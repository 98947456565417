import './styles/global.sass';
import './assets/icons/style.css'
import DataContextProvider from "./context/DataContext";
import "./i18n/config"
import { HelmetProvider } from 'react-helmet-async'
import Main from "./components/Main";

function App() {
  return (
      <DataContextProvider>
          <HelmetProvider>
              <Main/>

          </HelmetProvider>
      </DataContextProvider>
  );
}

export default App;
